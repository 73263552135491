<template>
  <form class="form" @submit.prevent="handleEditProfile">
    <div class="form-group row">
      <label for="language" class="col-lg-4 col-form-label text-lg-right">
        Langue de l'application
      </label>
      <div class="col-lg-7">
        <select class="form-control" id="language">
          <option value="Fr">Français</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="contact" class="col-lg-4 col-form-label text-lg-right">
        Genre
        <span class="text-danger">*</span>
      </label>
      <div class="col-lg-7">
        <select
          class="form-control"
          id="contact"
          v-model="profile.genre"
          :class="{
            'is-invalid': $v.profile.genre.$error || errors.genre !== undefined
          }"
          @blur="$v.profile.genre.$touch()"
        >
          <option :value="null" disabled>--Choisir--</option>
          <option value="Masculin">Masculin</option>
          <option value="Féminin">Féminin</option>
        </select>
        <template v-if="$v.profile.genre.$error || errors.genre">
          <label class="small form-text text-danger text-right">
            <em v-if="!$v.profile.genre.required">Veuillez choisir le genre</em>
            <em v-if="errors.genre">{{ errors.genre[0] }}</em>
          </label>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-4 col-form-label text-lg-right">
        Nom & Prénom
        <span class="text-danger">*</span>
      </label>
      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-6 pr-3 pr-sm-1">
            <input
              type="text"
              class="form-control"
              v-model="profile.nom"
              placeholder="Nom de famille"
              :class="{
                'is-invalid': $v.profile.nom.$error || errors.nom !== undefined
              }"
              @blur="$v.profile.nom.$touch()"
            />
            <template v-if="$v.profile.nom.$error || errors.nom">
              <label class="small form-text text-danger text-right">
                <em v-if="!$v.profile.nom.required">Veuillez entrer le nom</em>
                <em v-if="errors.nom">{{ errors.nom[0] }}</em>
              </label>
            </template>
          </div>
          <div class="col-lg-6 pl-3 pl-sm-1">
            <input
              type="text"
              class="form-control"
              v-model="profile.prenom"
              placeholder="Prénom"
              :class="{
                'is-invalid':
                  $v.profile.prenom.$error || errors.prenom !== undefined
              }"
              @blur="$v.profile.prenom.$touch()"
            />
            <template v-if="$v.profile.prenom.$error || errors.prenom">
              <label class="small form-text text-danger text-right">
                <em v-if="!$v.profile.prenom.required"
                  >Veuillez entrer le prénom</em
                >
                <em v-if="errors.prenom">{{ errors.prenom[0] }}</em>
              </label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-4 col-form-label text-lg-right">
        Date de naissance
        <span class="text-danger">*</span>
      </label>
      <div class="col-lg-7">
        <BaseDatepicker
          :is-invalid="
            $v.profile.date_naiss.$error || errors.date_naiss !== undefined
          "
          @blur="$v.profile.date_naiss.$touch()"
          :not-after="dateNotAfter"
          v-model="profile.date_naiss"
          placeholder="Choisir la date"
        />
        <template v-if="$v.profile.date_naiss.$error || errors.date_naiss">
          <label class="small form-text text-danger text-right">
            <em v-if="!$v.profile.date_naiss.required"
              >Veuillez entrez la date de naissance</em
            >
            <em v-if="errors.date_naiss">{{ errors.date_naiss[0] }}</em>
          </label>
        </template>
      </div>
    </div>

    <div class="form-group row" v-if="$v.$anyError || error">
      <div class="col-lg-8 offset-lg-4">
        <ul class="list-unstyled">
          <li class="text-danger" v-if="$v.$anyError">
            <i class="fa fa-times"></i> Veuillez remplir correctement tous les
            champs.
          </li>
          <li class="text-danger" v-if="error">
            <i class="fa fa-times"></i> {{ error }}
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group mb-0 text-center">
      <BaseButton
        buttonClass="mt-3 btn-secondary"
        :disabled="$v.$invalid || !editing"
        :loading="loading"
        :show-loading="true"
      >
        <i class="fa fa-save" v-if="!loading"></i>
        Enregistrer
      </BaseButton>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "FormProfile",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isCompleteProfile"]),
    ...mapState(["countries"]),
    isEditablePhone() {
      return this.isCompleteProfile
        ? this.isCurrentUserMerchant
        : this.user && !this.user.phone_verified_at;
    },
    dateNotAfter() {
      let d = new Date();
      d.setFullYear(d.getFullYear() - 15);
      return d;
    }
  },

  watch: {
    profile: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && oldValue.countries_id) this.editing = true;
      }
    }
  },

  data() {
    return {
      loading: false,
      editing: false,
      profile: {
        countries_id: null,
        nom: null,
        prenom: null,
        genre: null,
        date_naiss: null
      },
      errors: [],
      error: null
    };
  },

  created() {
    this.profile = Object.assign({}, this.user);
  },

  methods: {
    handleEditProfile() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        this.errors = [];
        this.$store
          .dispatch("auth/updateUser", Object.assign({}, this.profile))
          .then(() => {
            location.reload();
          })
          .catch(err => {
            if (err.errors) this.errors = err.errors;
            if (err.message) {
              this.error = err.message;
              if (this.errors["email"]) this.error = this.errors["email"][0];
            }
            this.loading = false;
          });
      }
    }
  },

  validations: {
    profile: {
      countries_id: { required },
      nom: { required },
      prenom: { required },
      genre: { required },
      date_naiss: { required }
    }
  }
};
</script>

<style scoped></style>
