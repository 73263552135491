import { render, staticRenderFns } from "./FormProfile.vue?vue&type=template&id=32ada8ec&scoped=true&"
import script from "./FormProfile.vue?vue&type=script&lang=js&"
export * from "./FormProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ada8ec",
  null
  
)

export default component.exports